<template>
	<v-layout row wrap align-center justify-center>
		<v-flex grow>
			<FileSearcher v-model="model" />
		</v-flex>
		<v-flex shrink>
			<FileFilterMenu v-model="model" />
		</v-flex>
	</v-layout>
</template>

<script>
import DocumentsManagerModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentsManagerModuleGuard'

export default {
	name: 'DocumentsToolbarSearch',
	components: {
		FileFilterMenu: () => ({
			component: import('@/components/Documents/Actions/Filter/FileFilterMenu')
		}),
		FileSearcher: () => ({
			component: import('@/components/Documents/Actions/Search/FileSearcher')
		})
	},
	mixins: [DocumentsManagerModuleGuard],
	props: {
		value: {
			type: Object,
			required: false,
			default: null
		}
	},
	computed: {
		model: {
			get: function () {
				return this.value
			},
			set: function (newValue) {
				this.$emit('input', newValue)
			}
		}
	}
}
</script>
